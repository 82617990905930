import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { Unauthorized } from '../helpers/Unauthorized'
import { Taxes } from "./Taxes";
import { CartRender } from "./CartRender";
import { Facturacion } from "./Facturacion";
import { SpinnerGif } from "../helpers/SpinnerGif";
import { SwalFire } from '../helpers/SwalFire'
import './Checkout.css'
import { cartContext } from '../Context/CartContext'
import { authContext } from '../Context/AuthContext'
import { useTranslation } from 'react-i18next'

const urlApiCheckout = process.env.REACT_APP_API_CHECKOUT

const Checkout = () => {
  const { cartLocal, deleteByUser, selectedFile } = useContext(cartContext)
  const { token, user, desloguearse } = useContext(authContext)
  const navigate = useNavigate()
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false)
  const [t, i18next] = useTranslation("global")
  const [precompra, setPrecompra] = useState(JSON.parse(localStorage.getItem('precompra')))
  // Valido su selected file viene distinto a su valor por defecto
  const hasContent = selectedFile && Object.keys(selectedFile).length > 0;
  const [actualizando, setActualizando] = useState(false);
  const [tiposDeEntrega, setTiposDeEntrega] = useState([]);
  const [condPago, setCondPago] = useState([]);
  const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS
  const country = process.env.REACT_APP_COUNTRY

  console.log("Este es el valor del file cargado: ", selectedFile)

  if (!token && !precompra) return <Unauthorized />

  let taxPesos = [];
  let taxDolares = [];

  function guardar(moneda, array) {
    for (let item in moneda) {
      if (moneda[item] > 0) {
        let nuevo = { [item]: moneda[item].toLocaleString() }
        array.push(nuevo);
      }
    }
  }

  let itemsMapeados = [];

  function mapearItems(items) {
    for (let i = 0; i < items.length; i++) {
      itemsMapeados.push({
        Alias: items[i].Alias,
        Moneda: items[i].Moneda,
        Precio: items[i].Precio,
        Cantidad: items[i].Cantidad,
      })
    }
  }

  guardar(precompra.Subtotal_Pesos, taxPesos);
  guardar(precompra.Subtotal_Dolares, taxDolares);

  mapearItems(precompra.items);


  const data = {
    precompra: precompra,
    cliente: {
      ID_CLIENTE: user.Id,
      name_: user.Nombre,
      lastname: user.Apellido,
      email: user.Email,
      dni: user.Cuit,
      telefono: user.Domicilio_facturacion.Telefono,
      calle: user.Domicilio_facturacion.Domicilio,
      numero: '1',
      localidad: user.Domicilio_facturacion.Localidad,
      codigoPostal: user.Domicilio_facturacion.Codigo_postal,
    },
  }

  // Si existe selectedFile, lo adjunto a la data
  if (selectedFile && hasContent) {
    data.archivoTransferencia = selectedFile;
  }

  const infoInputs = (e) => {
    let name = e.target.name
    let value = e.target.value
    setInfo(values => ({ ...values, [name]: value }))
  }

  const goToSuccess = (param) => {
    navigate(`/success`, {
      state: {
        param
      }
    });
  }

  const actualizarCondicionesDePagoYEntrega = async () => {
    // Si actualizando es true hago que el efecto se detenga y no haga solicudes adicionales
    if (actualizando) return;
    setActualizando(true);
    let entregaSeleccionada, pagoSeleccionado;

    // Seteo los valores de la entrega al encontrar la seleccion, y sino que sea los vcalores tomados del usuario
    if (info.entrega) {
      entregaSeleccionada = tiposDeEntrega.find(entrega => entrega.Descripcion === info.entrega) || user.Tipo_entrega;
    }

    if (info.pago) {
      pagoSeleccionado = condPago.find(condicion => condicion.Codigo === info.pago) || user.Condicion_Pago;
    }

    const precompraActualizada = { ...precompra };

    if (entregaSeleccionada) {
      precompraActualizada.tipo_entrega = { Codigo: entregaSeleccionada.Codigo, Descripcion: entregaSeleccionada.Descripcion };
    }

    if (info.entrega === "ENTREGA") {
      precompraActualizada.Direccion_Entrega = {
        Domicilio: user.Domicilio_entrega.Domicilio,
        Direccion: user.Domicilio_entrega.Domicilio,
        Localidad: user.Domicilio_entrega.Localidad,
        codigoPostal: user.Domicilio_entrega.Codigo_postal,
        Codigo_Postal: user.Domicilio_entrega.Codigo_postal,
        Codigo_Prov: user.Domicilio_entrega.Codigo_Prov,
        Pais: user.Domicilio_entrega.Pais
      }
    }

    if (pagoSeleccionado) {
      precompraActualizada.cond_pago = { Codigo: pagoSeleccionado.Codigo, Descripcion: pagoSeleccionado.Descripcion };
    }

    try {
      const response = await axios.post(`${urlApiPedidos}/proforma`, { precompra: precompraActualizada }, { headers: { 'Authorization': 'Bearer ' + token } });
      setPrecompra(response.data);
      console.log("Respuesta del post a proforma:", response.data);
      localStorage.setItem('precompra', JSON.stringify(response.data));
    } catch (error) {
      console.error('Error al actualizar precompra:', error);
    }

    setActualizando(false);
  };

  // console.log(hasContent)
  // console.log(selectedFile)
  // console.log(info.pago)
  // console.log(info.entrega)
  // console.log(info)

  const enviarOrden = async (e) => {
    e.preventDefault();
    setActualizando(true);
    const precompraLocalStorage = JSON.parse(localStorage.getItem('precompra'));

    if (!precompraLocalStorage) {
      console.error('La información de precompra en localStorage no está disponible o no está actualizada.');
      setActualizando(false);
      return;
    }

    data.precompra = precompraLocalStorage

    if (!info.pago || !info.entrega) {
      setActualizando(false);
      SwalFire(t("checkout.seleccione-metodo"), 'warning');
      return;
    }

    let AxiosOptions;
    // COMENTADO OPTION DE PAGUELO FACIL HASTA QUE ESTE CONFIRMADO
    // if (info.pago === 'PF' && info.entrega === 'RETIRA' && country === "PA") {
    //   AxiosOptions = {
    //     method: 'post',
    //     url: 'https://testpa.solutionbox.com.pa/api/checkout/ordenwebpf',
    //     headers: { 'Authorization': 'Bearer ' + token },
    //     data: data
    //   };

    //   try {
    //     setLoad(true);
    //     const res = await axios(AxiosOptions);
    //     if (res.status === 401) {
    //       setLoad(false);
    //       desloguearse();
    //       return;
    //     }
    //     if (res.status === 429) {
    //       setLoad(false);
    //       return;
    //     }
    //     if (res.status === 200) {
    //       // Redirect 
    //       window.location.href = res.data.data.url;
    //       setLoad(false);
    //       //   goToSuccess(res.data);
    //       //  deleteByUser();

    //     } else {
    //       // Manejar el caso en el que la estructura de la respuesta no es la esperada
    //       console.error('La estructura de la respuesta no es la esperada');
    //     }
    //   } catch (err) {
    //     setLoad(false);
    //     SwalFire('Error', err.response.data.message, 'warning')
    //       .then(() => {
    //         if (err.response.status === 401) {
    //           desloguearse();
    //           navigate('/');
    //         }
    //       });
    //   } finally {
    //     setLoad(false);
    //   }

    // } else {
    if (selectedFile && hasContent && info.pago === "PRE PAY" && info.entrega === "RETIRA") {
      const formPedidoTransferencia = new FormData();
      formPedidoTransferencia.append('precompra', JSON.stringify(data.precompra));
      formPedidoTransferencia.append('cliente', JSON.stringify(data.cliente));
      const contentFile = new Blob([selectedFile.content], { type: selectedFile.type });
      formPedidoTransferencia.append('archivoTransferencia', contentFile, selectedFile.filename);
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/transferencia/success`,
        headers: {
          'Authorization': 'Bearer ' + token,
          "Content-Type": "multipart/form-data"
        },
        data: formPedidoTransferencia
      };
    } else if (country === "UY" && hasContent) {
      const formPedidoTransferencia = new FormData();
      formPedidoTransferencia.append('precompra', JSON.stringify(data.precompra));
      formPedidoTransferencia.append('cliente', JSON.stringify(data.cliente));
      const contentFile = new Blob([selectedFile.content], { type: selectedFile.type });
      formPedidoTransferencia.append('archivoTransferencia', contentFile, selectedFile.filename);
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/transferencia/success`,
        headers: {
          'Authorization': 'Bearer ' + token,
          "Content-Type": "multipart/form-data"
        },
        data: formPedidoTransferencia
      };
    } else {
      AxiosOptions = {
        method: 'post',
        url: `${urlApiCheckout}/pedido/success`,
        headers: { 'Authorization': 'Bearer ' + token },
        data: data
      };
    }

    try {
      const res = await axios(AxiosOptions);
      setActualizando(false);
      if (res.status === 401) {
        setLoad(false);
        desloguearse();
        return;
      }
      if (res.status === 429) {
        setLoad(false);
        return;
      }
      if (res.status === 200) {
        setLoad(false);
        goToSuccess(res.data);
        deleteByUser();
      }
    } catch (err) {
      setLoad(false);
      setActualizando(false);
      SwalFire('Error', err.response.data.message, 'warning')
        .then(() => {
          if (err.response.status === 401) {
            desloguearse();
            navigate('/');
          }
        });
    }
    // }
  };




  if (load) return <SpinnerGif text={t("checkout.procesando-compra")} />

  if (!token) return <Unauthorized />

  return (
    <div className='divCheckout'>
      <h1>{t("checkout.resumen-compra")}</h1>

      <CartRender cartLocal={cartLocal} />

      <Taxes taxPesos={taxPesos} taxDolares={taxDolares} />

      <Facturacion
        user={user}
        info={info}
        enviarOrden={enviarOrden}
        infoInputs={infoInputs}
        tiposDeEntrega={tiposDeEntrega}
        setTiposDeEntrega={setTiposDeEntrega}
        condPago={condPago}
        setCondPago={setCondPago}
        actualizarCondicionesDePagoYEntrega={actualizarCondicionesDePagoYEntrega}
        actualizando={actualizando}
        setActualizando={setActualizando}
      />

      <Button variant='secondary' onClick={() => navigate('/cart')}>
        <i className="fa-sharp fa-solid fa-circle-chevron-left"></i>
        {t("checkout.volver-carrito")}
      </Button>
    </div>
  )
}

export default Checkout